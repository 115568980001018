import { PageRoutes } from "~/enums/PageRoutes"
import { Podcasts } from "~/enums/Podcasts"
import type { Maybe } from "~/types/ts-utils"
import { publicConfig } from "~/utils/config"
import { replacePathParams } from "~/utils/next/replacePathParams"

import { DocumentType } from "./enums"

type Doc = {
  type: string
  uid?: Maybe<string>
  podcastUid?: Maybe<string>
}

export const linkResolver = (doc: Doc): string => {
  const type = doc.type.toLowerCase()
  if (doc.uid) {
    const { uid, podcastUid = Podcasts.INTERRUPTOR } = doc
    const getPath = (path: PageRoutes) => replacePathParams(path, { uid })

    switch (type) {
      case DocumentType.ARTICLE:
        return getPath(PageRoutes.ARTICLE)

      case DocumentType.CATEGORY:
        return getPath(PageRoutes.CATEGORY)

      case DocumentType.CONTENT_THEME:
        return getPath(PageRoutes.CONTENT_THEME)

      case DocumentType.NEWSLETTER:
        return getPath(PageRoutes.NEWSLETTER)

      case DocumentType.PODCAST:
        return getPath(PageRoutes.PODCAST)

      case DocumentType.PODCAST_EPISODE: {
        return replacePathParams(PageRoutes.PODCAST_EPISODE, {
          uid: podcastUid,
          episodeUid: uid,
        })
      }
    }
  }

  switch (type) {
    case DocumentType.ABOUT_PAGE:
      return PageRoutes.ABOUT

    case DocumentType.DATA_PAGE:
      return PageRoutes.DATA

    case DocumentType.NEWSLETTER_SERIES:
      return PageRoutes.NEWSLETTER_SERIES

    case DocumentType.PRIVACY_POLICY:
      return PageRoutes.PRIVACY_POLICY

    case DocumentType.CONTRIBUTE:
      return PageRoutes.CONTRIBUTE

    default:
      return "/"
  }
}

export function urlResolver(doc: Doc): string {
  return `${publicConfig.siteURL}${linkResolver(doc)}`
}
