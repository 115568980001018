import clsx from "clsx"

import type { ColorScheme, ContentRelationship } from "~/api/prismic/types"
import type { ColorSchemeUIDs } from "~/enums/ColorSchemes"
import type { Maybe } from "~/types/ts-utils"

import { getRelatedColorSchemeId } from "./getRelatedColorSchemeId"

type ClassVariations = {
  light: string
  dark: string
}

type Classes = Record<ColorSchemeUIDs, ClassVariations>

const textClasses: Classes = {
  black: {
    light: "text-white",
    dark: "dark:text-white",
  },
  violet: {
    light: "text-primary-700 theme-peach:text-primary-900",
    dark: "dark:text-primary-50",
  },
  "cs-blue": {
    light: "text-blue-800",
    dark: "dark:text-blue-50",
  },
  "cs-pink": {
    light: "text-pink-800",
    dark: "dark:text-pink-50",
  },
  "cs-teal": {
    light: "text-teal-800",
    dark: "dark:text-teal-50",
  },
  "forest-green": {
    light: "text-forestGreen-800",
    dark: "dark:text-forestGreen-50",
  },
}

const bgContrastClasses: Classes = {
  black: {
    light: "bg-gray-800",
    dark: "dark:bg-gray-800",
  },
  violet: {
    light: "bg-primary-300",
    dark: "dark:bg-primary-600",
  },
  "cs-blue": {
    light: "bg-blue-300",
    dark: "dark:bg-blue-600",
  },
  "cs-pink": {
    light: "bg-pink-300",
    dark: "dark:bg-pink-600",
  },
  "cs-teal": {
    light: "bg-teal-300",
    dark: "dark:bg-teal-600",
  },
  "forest-green": {
    light: "bg-forestGreen-300",
    dark: "dark:bg-forestGreen-600",
  },
}

const bgClasses: Classes = {
  black: {
    light: "bg-gray-800",
    dark: "dark:bg-gray-800",
  },
  violet: {
    light: "bg-primary-200",
    dark: "dark:bg-primary-700",
  },
  "cs-blue": {
    light: "bg-blue-200",
    dark: "dark:bg-blue-700",
  },
  "cs-pink": {
    light: "bg-pink-200",
    dark: "dark:bg-pink-700",
  },
  "cs-teal": {
    light: "bg-teal-200",
    dark: "dark:bg-teal-700",
  },
  "forest-green": {
    light: "bg-forestGreen-200",
    dark: "dark:bg-forestGreen-700",
  },
}

type ColorSchemeClasses = {
  text: string
  bgContrast: string
  bg: string
}

type ColorSchemeClassesOptions = {
  colorScheme?: ContentRelationship<ColorScheme> | { uid: Maybe<string> }
  lightOnly?: boolean
  defaults?: ColorSchemeClasses
}

const joinClasses = ({ light, dark }: ClassVariations, lightOnly = false) => {
  return clsx(light, !lightOnly && dark)
}

const defaults: ColorSchemeClasses = {
  text: joinClasses(textClasses.violet),
  bgContrast: joinClasses(bgContrastClasses.violet),
  bg: joinClasses(bgClasses.violet),
}

export const getClassesByColorScheme = ({
  colorScheme,
  lightOnly = false,
}: ColorSchemeClassesOptions): ColorSchemeClasses => {
  const id = getRelatedColorSchemeId(colorScheme)

  const text = textClasses[id] || defaults.text
  const bg = bgClasses[id] || defaults.bg
  const bgContrast = bgContrastClasses[id] || defaults.bgContrast

  return {
    text: joinClasses(text, lightOnly),
    bgContrast: joinClasses(bgContrast, lightOnly),
    bg: joinClasses(bg, lightOnly),
  }
}
