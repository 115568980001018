import clsx from "clsx"

import LazyImage from "~/components/LazyImage"
import Link from "~/components/Link"
import { ColorSchemeUIDs } from "~/enums/ColorSchemes"
import type { MaybeImage } from "~/types/images"
import type { Maybe } from "~/types/ts-utils"
import { getChunkyUnderlineClass } from "~/utils/style/getChunkyUnderlineClass"

import type { CategoriesProps } from "./Categories"
import { Categories } from "./Categories"

export type FeaturedArticleProps = {
  uid: string
  title: string
  description: Maybe<string>
  href: string
  image: MaybeImage
  categories: CategoriesProps["categories"]
  isFirst: boolean
}

export const FeaturedArticle: React.FC<FeaturedArticleProps> = ({
  title,
  href,
  description,
  image,
  uid,
  categories,
  isFirst,
}) => {
  const titleID = `featured-article-title-${uid}`

  return (
    <div className={clsx("grid", { "md:grid-cols-6": isFirst })}>
      <Link
        href={href}
        className={clsx("relative w-full pb-9/16", {
          "md:col-span-3": isFirst,
        })}
        aria-labelledby={titleID}
      >
        {image && (
          <LazyImage
            className={clsx(
              "absolute top-0 left-0 z-[-1] h-full w-full object-cover",
            )}
            src={image.url}
            alt={image.alt || ""}
            reallyLazy={!isFirst}
          />
        )}
      </Link>

      <div
        className={clsx("p-4", {
          "md:col-span-3": isFirst,
          "lg:pr-0": isFirst,
          "lg:px-0": !isFirst,
        })}
      >
        <Link href={href}>
          <h2
            id={titleID}
            className={clsx(
              "inline text-xl",
              getChunkyUnderlineClass(ColorSchemeUIDs.VIOLET),
              { "md:text-2xl": isFirst },
            )}
          >
            {title}
          </h2>
        </Link>

        <Categories categories={categories} />

        <Link href={href}>
          <p className="mt-2">{description}</p>
        </Link>
      </div>
    </div>
  )
}
