import clsx from "clsx"

import type { LinkProps } from "."
import { Link } from "."

export const ButtonLink: React.FC<LinkProps> = ({ className, ...props }) => {
  return (
    <Link
      {...props}
      className={clsx(
        "font-display font-semibold md:text-lg",
        "bg-secondary-500 text-primary-800 theme-peach:text-black",
        "rounded py-2 px-4 md:py-3 md:px-6",
        className,
      )}
    />
  )
}
