import clsx from "clsx"

import type { FeaturedArticleProps } from "./FeaturedArticle"
import { FeaturedArticle } from "./FeaturedArticle"

export type HighlightsSliceProps = {
  highlights: Omit<FeaturedArticleProps, "isFirst">[]
}

export const HighlightsSlice: React.FC<HighlightsSliceProps> = ({
  highlights,
}) => {
  if (!highlights.length) return null

  return (
    <ul
      className={clsx(
        "grid gap-4 md:grid-cols-3 md:gap-8",
        "w-full max-w-screen-lg",
        "mx-auto mb-8 md:mt-4",
      )}
    >
      {highlights.map((article, index) => {
        const isFirst = index === 0

        return (
          <li key={article.href} className={clsx({ "md:col-span-3": isFirst })}>
            <FeaturedArticle {...article} isFirst={isFirst} />
          </li>
        )
      })}
    </ul>
  )
}
