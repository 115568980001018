import type { PageRoutes } from "~/enums/PageRoutes"

export type ReplacePathParamsArgs = {
  path: PageRoutes
  params: Record<string, unknown>
}

export const replacePathParams = (
  path: PageRoutes,
  params: Record<string, unknown>,
): string => {
  return Object.entries(params).reduce<string>(
    (result, [key, value]) => result.replace(`[${key}]`, String(value)),
    path,
  )
}
