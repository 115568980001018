import { useTranslation } from "react-i18next"
import type { NextPage } from "next"
import clsx from "clsx"

import { ButtonLink } from "~/components/Link/ButtonLink"
import { SEO } from "~/components/SEO"
import { PageRoutes } from "~/enums/PageRoutes"

import { FeaturedPodcastEpisode } from "./FeaturedPodcastEpisode"
import { HighlightsSlice } from "./Highlights"
import { SeriesSlice } from "./Series"
import { SingleFeaturedArticle } from "./SingleFeaturedArticle"
import type { HomepageSlice } from "./types"

export type HomePageProps = {
  body: HomepageSlice[]
}

export const Home: NextPage<HomePageProps> = ({ body }) => {
  const { t } = useTranslation("home")

  return (
    <>
      <SEO pageTitle={t("metadata.title")} withTitleSuffix={false} path="/" />

      {body.map((slice, index) => {
        const key = `${slice.type}-${index}`

        switch (slice.type) {
          case "Series":
            return <SeriesSlice key={key} {...slice.props} />

          case "Highlights":
            return <HighlightsSlice key={key} {...slice.props} />

          case "SingleFeaturedArticle":
            return <SingleFeaturedArticle key={key} {...slice.props} />

          case "FeaturedPodcastEpisode":
            return <FeaturedPodcastEpisode key={key} {...slice.props} />

          default:
            return null
        }
      })}

      <div
        className={clsx(
          "flex flex-col items-center",
          "bg-primary-500 text-white dark:bg-primary-700",
          "p-8 md:py-16",
        )}
      >
        <ButtonLink href={PageRoutes.ARTICLES_LIST}>
          {t("seeAllArticles")}
        </ButtonLink>
      </div>
    </>
  )
}
