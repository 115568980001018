import type { GetStaticProps } from "next"
import { uniqBy } from "lodash"

import { sendPrismicGraphQLRequest } from "~/api/prismic/graphql/client"
import type { HomepageQuery } from "~/api/prismic/graphql/queries"
import { HomepageDocument } from "~/api/prismic/graphql/queries"
import type { HomePageProps } from "~/screens/HomePage"
import { Home } from "~/screens/HomePage"
import {
  mapHomepageSlicesToBody,
  mapPodcastEpisodeProps,
} from "~/screens/HomePage/helpers"
import { getHomepageArticlesByCategory } from "~/screens/HomePage/queries"
import { filterByTypename } from "~/utils/graphql"
import { enhanceStaticProps } from "~/utils/next/enhanceStaticProps"
import { getPreviewRef } from "~/utils/prismic/previewData"

export const getStaticProps: GetStaticProps<HomePageProps> = enhanceStaticProps(
  async ({ previewData }) => {
    const { homepage, podcastEpisodes } =
      await sendPrismicGraphQLRequest<HomepageQuery>({
        query: HomepageDocument,
        clientOptions: { ref: getPreviewRef(previewData) },
      })

    if (!homepage) throw new Error("homepage not found")

    const allCategories = filterByTypename(
      homepage.body,
      "HomepageBodySeries",
    ).flatMap(({ fields }) => {
      const categories = fields?.map((c) => c.category)

      return filterByTypename(categories, "Category")
    })

    const uniqueCategories = uniqBy(allCategories, (x) => x._meta.id)

    const articlesByCategory = await Promise.all(
      uniqueCategories.map(async (category) => {
        const { id } = category._meta
        const articles = await getHomepageArticlesByCategory({
          id,
          ref: getPreviewRef(previewData),
        })

        return { id, articles }
      }),
    )

    return {
      props: {
        body: mapHomepageSlicesToBody({
          homepageQuery: homepage,
          articlesByCategory,
          podcastEpisode: mapPodcastEpisodeProps(podcastEpisodes),
        }),
      },
      revalidate: 15,
    }
  },
)

export default Home
