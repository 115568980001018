import clsx from "clsx"

import LazyImage from "~/components/LazyImage"
import Link from "~/components/Link"
import type { MaybeImage } from "~/types/images"
import type { Maybe } from "~/types/ts-utils"

export type SingleFeaturedArticleProps = {
  title: string
  description: Maybe<string>
  href: string
  image: MaybeImage
}

export const SingleFeaturedArticle: React.FC<SingleFeaturedArticleProps> = ({
  href,
  title,
  description,
  image,
}) => {
  return (
    <article className="flex flex-col-reverse bg-primary-50 dark:bg-primary-900 md:flex-row">
      <div
        className={clsx(
          "relative flex flex-col-reverse md:flex-row md:items-center",
          "mx-auto w-full max-w-screen-lg",
          "md:my-8",
        )}
      >
        <div
          className={clsx(
            "relative z-[1]",
            "mx-4 mb-4 md:my-auto md:mx-0 md:max-w-sm",
            "border-b-4 md:border-b-0 md:border-l-4",
            "border-primary-500 dark:border-primary-500",
          )}
        >
          <div
            className={clsx(
              "p-4 md:py-8 md:px-6",
              "bg-white dark:bg-primary-700",
            )}
          >
            <h1
              className={clsx(
                "text-2xl md:text-3xl",
                "inline chunky-underline-primary-200 dark:chunky-underline-primary-500",
              )}
            >
              <Link href={href}>{title}</Link>
            </h1>
            <div className="mt-4">{description}</div>
          </div>
        </div>
        {image?.url && (
          <div className="w-full">
            <div className="relative -mb-8 w-full pb-9/16 md:mb-0 md:-ml-20">
              <LazyImage
                className="absolute top-0 left-0 h-full w-full"
                src={image.url}
                alt={image.alt || ""}
              />
            </div>
          </div>
        )}
      </div>
    </article>
  )
}
