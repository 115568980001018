import clsx from "clsx"

import Link from "~/components/Link"
import type { Maybe } from "~/types/ts-utils"
import { getClassesByColorScheme } from "~/utils/style/colorSchemes"

type CategoryLink = {
  colorScheme: Maybe<string>
  name: string
  href: string
}

export type CategoriesProps = {
  categories: CategoryLink[]
}

export const Categories: React.FC<CategoriesProps> = ({ categories }) => {
  if (!categories.length) return null

  return (
    <div className="mt-4 mb-2 flex flex-wrap">
      {categories.map((category) => {
        const { colorScheme, href, name } = category
        const classes = getClassesByColorScheme({
          colorScheme: {
            uid: colorScheme,
          },
          lightOnly: true,
        })

        return (
          <Link
            key={name}
            className={clsx(
              "font-display text-sm font-bold",
              `mr-1 mb-1 px-2`,
              classes.text,
              classes.bg,
            )}
            href={href}
          >
            {name}
          </Link>
        )
      })}
    </div>
  )
}
