import type { ColorScheme, ContentRelationship } from "~/api/prismic/types"
import { ColorSchemeUIDs } from "~/enums/ColorSchemes"
import type { Maybe } from "~/types/ts-utils"

export function getRelatedColorSchemeId(
  colorScheme?: ContentRelationship<ColorScheme> | { uid: Maybe<string> },
): ColorSchemeUIDs {
  if (!colorScheme || !("uid" in colorScheme) || !colorScheme.uid) {
    return ColorSchemeUIDs.VIOLET
  }

  return colorScheme.uid as ColorSchemeUIDs
}
