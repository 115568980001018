import React from "react"
import { ChevronRight } from "react-feather"
import { useTranslation } from "react-i18next"
import { Tab } from "@headlessui/react"
import clsx from "clsx"

import { DocumentType } from "~/api/prismic/enums"
import { linkResolver } from "~/api/prismic/linkResolver"
import Link from "~/components/Link"
import type { Maybe } from "~/types/ts-utils"
import { getChunkyUnderlineClass } from "~/utils/style/getChunkyUnderlineClass"

import type { ArticlesByCategoryProps } from "./ArticlesByCategory"
import { ArticlesByCategory } from "./ArticlesByCategory"

export type SeriesSliceProps = {
  categories: {
    id: string
    uid: string
    name: string
    colorScheme: Maybe<string>
    articles: ArticlesByCategoryProps["articles"]
  }[]
}

export const SeriesSlice: React.FC<SeriesSliceProps> = ({ categories }) => {
  const { t } = useTranslation("home")

  if (!categories.length) return null

  return (
    <section className="mx-auto max-w-screen-lg py-4 md:py-8">
      <Tab.Group>
        {({ selectedIndex }) => {
          const selectedCategory = categories[selectedIndex || 0]

          return (
            <>
              <div className="flex flex-col items-start md:flex-row md:items-center">
                <h1 className="bg-primary-500 py-1 px-2 text-lg font-black text-white theme-peach:text-black">
                  {t("categories.featured.title")}
                </h1>

                <Tab.List className="px-2 md:px-4">
                  {categories.map((category) => {
                    return (
                      <Tab
                        key={category.id}
                        className={({ selected }) =>
                          clsx(
                            "my-2 mr-4 inline-block",
                            "font-display text-sm font-medium",
                            "cursor-pointer",
                            {
                              "!font-black": selected,
                              [getChunkyUnderlineClass()]: selected,
                            },
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    )
                  })}
                </Tab.List>
              </div>
              <div
                className={clsx(
                  "flex items-start justify-end",
                  "space-x-2 px-4 font-display",
                  "border-t-2 border-primary-500",
                )}
              >
                <Link
                  className={clsx(
                    "mt-2 flex items-center text-sm font-bold",
                    getChunkyUnderlineClass(null),
                  )}
                  href={linkResolver({
                    uid: selectedCategory.uid,
                    type: DocumentType.CATEGORY,
                  })}
                >
                  {t("categories.featured.readMore")}
                  <ChevronRight className="shrink-0" size={16} />
                </Link>
              </div>
              <Tab.Panels>
                {categories.map((category) => {
                  return (
                    <Tab.Panel key={category.id}>
                      <ArticlesByCategory articles={category.articles} />
                    </Tab.Panel>
                  )
                })}
              </Tab.Panels>
            </>
          )
        }}
      </Tab.Group>
    </section>
  )
}
