import { Pause, Play } from "react-feather"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

import { Button } from "~/components/Button"
import LazyImage from "~/components/LazyImage"
import Link from "~/components/Link"
import { useCurrentEpisodeUid, useIsPodcastPlaying } from "~/state/Podcast"
import type { MaybeImage } from "~/types/images"
import { AnalyticsEvent, trackEvent } from "~/utils/analytics/analytics.client"
import { imageOrFallback } from "~/utils/images/imageOrFallback"

export type FeaturedPodcastEpisodeProps = {
  uid: string
  image: MaybeImage
  title: string
  shortDescription: string
  linkToEpisode: string
}

export const FeaturedPodcastEpisode: React.FC<FeaturedPodcastEpisodeProps> = ({
  uid: episodeUid,
  image,
  title,
  shortDescription,
  linkToEpisode,
}) => {
  const { t } = useTranslation(["home", "podcasts"])
  const [currentEpisodeUid, setCurrentEpisodeUid] = useCurrentEpisodeUid()
  const [isPodcastPlaying, setIsPodcastPlaying] = useIsPodcastPlaying()

  const episodeImage = imageOrFallback(image?.url)

  const isCurrentPodcast =
    !!currentEpisodeUid && episodeUid === currentEpisodeUid

  const isPlaying = isCurrentPodcast && isPodcastPlaying
  const [Icon, buttonText] = isPlaying
    ? [Pause, "podcasts:buttons.pause"]
    : [
        Play,
        isCurrentPodcast
          ? "podcasts:buttons.play"
          : "podcasts:buttons.listenNow",
      ]

  return (
    <section
      className={clsx(
        "py-12 px-4 md:py-16",
        "bg-secondary-500 dark:bg-primary-800",
        "theme-peach:bg-secondary-200 theme-bw:bg-primary-200",
      )}
    >
      <div className="mx-auto max-w-screen-lg space-y-4 md:space-y-6">
        <h2 className="inline-flex bg-primary-500 py-1 px-2 text-white theme-peach:text-black md:text-lg">
          {t("home:featuredPodcast.latestEpisode")}
        </h2>

        <div
          className={clsx(
            "mx-auto max-w-screen-lg",
            "flex flex-col items-stretch justify-between sm:flex-row",
            "space-y-4 sm:space-y-0 sm:space-x-4 md:space-x-6",
          )}
        >
          <div className="h-40 min-h-full w-full shrink-0 sm:w-40 md:w-64">
            <LazyImage
              className="h-full w-full object-cover"
              src={episodeImage}
              alt=""
              reallyLazy
            />
          </div>
          <div className="grow space-y-4 md:space-y-6">
            <div className="flex items-center space-x-4">
              <Button
                onClick={() => {
                  setCurrentEpisodeUid(episodeUid)
                  setIsPodcastPlaying(!isCurrentPodcast || !isPlaying)

                  if (!isCurrentPodcast) {
                    trackEvent(AnalyticsEvent.HOMEPAGE_PLAY_PODCAST)
                  }
                }}
                type="button"
              >
                <Icon size={32} />
                <span className="sr-only">{t(buttonText)}</span>
              </Button>
              <h3 className="inline text-xl md:text-2xl">
                <Link href={linkToEpisode}>{title}</Link>
              </h3>
            </div>
            <p className="font-medium">
              <Link href={linkToEpisode}>{shortDescription}</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
