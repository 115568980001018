import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react"
import clsx from "clsx"

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={clsx(
        "flex items-center self-center",
        "space-x-4 rounded p-3",
        "bg-primary-500 text-white hover:text-secondary-500 theme-peach:text-black",
        "dark:bg-secondary-400 dark:text-primary-700",
        "dark:hover:bg-secondary-500 dark:hover:text-primary-700",
        "font-display font-semibold",
        className,
      )}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}
