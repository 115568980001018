import clsx from "clsx"

import LazyImage from "~/components/LazyImage"
import Link from "~/components/Link"
import { ColorSchemeUIDs } from "~/enums/ColorSchemes"
import type { Maybe } from "~/types/ts-utils"
import { usePrismicDateFormatter } from "~/utils/dates/useDateTimeFormatter"
import { imageOrFallback } from "~/utils/images/imageOrFallback"
import { getChunkyUnderlineClass } from "~/utils/style/getChunkyUnderlineClass"

import type { MaybeImage } from "../../types/images"

export type PreviewProps = {
  title: string
  date: Maybe<string>
  description: Maybe<string>
  href: string
  image: MaybeImage
  colorScheme?: ColorSchemeUIDs
  badge?: { text: string; classes: string }
}

export const PublishedItemPreview: React.FC<PreviewProps> = ({
  title,
  description,
  image,
  date,
  href,
  badge,
  colorScheme = ColorSchemeUIDs.VIOLET,
}) => {
  const formatDate = usePrismicDateFormatter({
    day: "numeric",
    month: "short",
    year: "numeric",
  })

  return (
    <Link className="no-underline" href={href}>
      <div className="flex flex-col">
        <div className="relative w-full shrink-0 pb-9/16">
          <LazyImage
            className="absolute top-0 left-0 h-full w-full object-cover"
            src={imageOrFallback(image?.url)}
            alt={image?.alt || ""}
          />

          {badge && (
            <span
              className={clsx(
                "absolute bottom-0 left-0 px-2 sm:right-0 sm:left-auto",
                "font-display text-sm font-semibold",
                badge.classes,
              )}
            >
              {badge.text}
            </span>
          )}
        </div>
        <div className="flex flex-col items-start p-2 py-4 md:p-4">
          <div>
            <h2
              className={clsx(
                "inline text-xl sm:mb-2",
                "text-black dark:text-white",
                getChunkyUnderlineClass(colorScheme),
              )}
            >
              {title}
            </h2>
          </div>
          {!!date && (
            <time
              className="mt-1 text-sm font-semibold text-primary-500 theme-peach:text-primary-800 dark:text-secondary-500"
              dateTime={date}
            >
              {formatDate(date)}
            </time>
          )}
          {!!description && (
            <div className="mt-2 flex flex-1 flex-col items-start">
              <p className="mb-2 text-gray-900 line-clamp-6 dark:text-gray-50">
                {description}
              </p>
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}
