import React from "react"
import clsx from "clsx"

import type { PreviewProps } from "~/components/PublishedItemPreview"
import { PublishedItemPreview } from "~/components/PublishedItemPreview"

export type ArticlesByCategoryProps = {
  articles: PreviewProps[]
}

export const ArticlesByCategory: React.FC<ArticlesByCategoryProps> = ({
  articles,
}) => {
  if (!articles) return null

  return (
    <div
      className={clsx(
        "my-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:my-8 md:gap-8",
        { "md:grid-cols-3": articles.length === 3 },
      )}
    >
      {articles.map((article) => (
        <PublishedItemPreview key={article.href} {...article} />
      ))}
    </div>
  )
}
