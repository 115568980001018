import { useTranslation } from "react-i18next"
import { parse } from "date-fns"

// e.g. 2020-05-30T12:19:33+0000
export const PRISMIC_TIMESTAMP_FORMAT = `yyyy-MM-dd'T'HH:mm:ssxx`

type FormatDate = (date: string | number | Date, dateFormat?: string) => string

export function useDateTimeFormatter(
  options: Intl.DateTimeFormatOptions,
): FormatDate {
  const { i18n } = useTranslation()
  const { language } = i18n

  const { format } = new Intl.DateTimeFormat(language, options)

  return (date: string | number | Date, dateFormat?: string) => {
    let value: number | Date

    if (typeof date === "string") {
      if (!dateFormat) {
        throw new Error("date is string, but no format info provided")
      }

      value = parse(date, dateFormat, new Date())
    } else {
      value = date
    }

    return format(value)
  }
}

type FormatPrismicDate = (date: string) => ReturnType<FormatDate>

export function usePrismicDateFormatter(
  options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  },
): FormatPrismicDate {
  const format = useDateTimeFormatter(options)

  return (date) => format(date, PRISMIC_TIMESTAMP_FORMAT)
}
